import '../styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';
import type { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useIsomorphicLayoutEffect } from 'react-use';
import { RecoilRoot } from 'recoil';

const MyApp = ({ Component, pageProps }: AppProps): unknown => {
    const { events, isReady } = useRouter();

    useEffect(() => {
        const handleRouterChangeComplete = (): void => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // if (window?.klaro) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // window.klaro.getManager().applyConsents(); // eslint-disable-line
            // }
        };

        events.on('routeChangeComplete', handleRouterChangeComplete);
    }, [events]);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useIsomorphicLayoutEffect(() => {
        if (isReady) {
            // TODO Klaro aktivieren, wenn GA genutzt wird
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // window.klaro.render(window.klaroConfig); // eslint-disable-line
        }
    }, [isReady]);

    return (
        <>
            <>
                <RecoilRoot>
                    <Component {...pageProps} />
                    <ToastContainer />
                </RecoilRoot>
            </>
        </>
    );
};

export default MyApp;
